import React from "react"
import EgMarketsLogo from "../../../assets/logo/EgMarketsLogo"
import ctl from "@netlify/classnames-template-literals"
import SocialMediaIcons from "../../utils/social-icons"

const FooterLinks = () => {
  const footerLinksStyle = ctl(`flex flex-col justify-between `)
  return (
    <section className={footerLinksStyle}>
      <EgMarketsLogo isLight />

      <SocialMediaIcons />
    </section>
  )
}

FooterLinks.propTypes = {}

export default FooterLinks
