import React from "react"

const FinancialMarketNews = ({ title, excerpt, read_more }) => {
  return (
    <div
      className='flex flex-col bg-white items-start pl-[25px] pr-[30px] py-4 border-b-black border-b-[0.5px]'
    >
      <h4 className='m-0 text-xs font-semibold text-black leading-[1.7] mb-[10px]'>
        {title}
      </h4>
      <p className='m-0 text-[10px] leading-[1.7] text-black'>
        {excerpt}…<a className='font-bold' target='_blank' rel='noreferrer' href={read_more}>read more</a>
      </p>
    </div>
  )
}

export default FinancialMarketNews