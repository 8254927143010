import React from "react"
import dropdownIcon from "icons/dropdown.svg"
import ctl from "@netlify/classnames-template-literals"
import CountBadge from "./count-badge"
import { NOTIFICATION_CATEGORIES } from "./constants"
import { motion } from "framer-motion"
import EconomicCalendar from "./categories/economic-calendar"
import FinancialMarketNews from "./categories/financial-market-news"
import WebinarsNotification from "./categories/webinars"

const NotificationCategory = ({ hideBorder = false, title, count, type, items, isOpen, onToggle }) => {
  const renderCategoryItem = ({ item, hideBorder }) => {
    if(type === NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR){
      return (
        <EconomicCalendar item={item} hideBorder={hideBorder}/>
      )
    }

    if(type === NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS){
      return <FinancialMarketNews {...item}/>
    }

    if(type === NOTIFICATION_CATEGORIES.WEBINARS){
      return <WebinarsNotification {...item}/>
    }

    return null
  }

  const renderItems = () => {
    let content;

    if(items?.length > 0){
      content = (
        <>
          {items.map((item, index) => renderCategoryItem({item, hideBorder: index === items.length - 1 }))}
        </>
      );
    }else{
      content = (
        <span className={`text-center block py-4 bg-white ${!hideBorder && 'border-b-black border-b-[0.5px]'}`}>
          No notifications found under this category
        </span>
      )
    }

    return (
      <motion.div
        className={`${isOpen ? 'overflow-auto' : 'overflow-hidden'} max-h-[255px]`}
        initial={false}
        animate={isOpen ? 'show' : 'hide'}
        variants={{
          show: {
            height: 'auto'
          },
          hide: {
            height: 0
          }
        }}
        transition={{duration: 0.15}}
      >
        {content}
      </motion.div>
    )
  }

  const showBorder = isOpen ? true : !hideBorder

  const wrapper = ctl(`
    h-[56px] flex items-center pl-[25px] pr-[30px] bg-white cursor-pointer
    ${showBorder && 'border-b-[0.5px] border-b-black'}
  `)

  return (
    <div>
      <header className={wrapper} onClick={onToggle}>
        <h5 className='font-extrabold text-xs text-black mr-[6px]'>
          {title}
        </h5>
        <CountBadge className={`mr-auto ${(count === 0) && 'opacity-0'}`}>
          {count}
        </CountBadge>
        <img src={dropdownIcon} alt="Dropdown" className={isOpen ? 'rotate-180' : ''}/>
      </header>
      {renderItems()}
    </div>
  )
}

export default NotificationCategory