import React from "react"

const FooterCompanyInfo = () => {
  return (
    <div className='md:text-lg max-w-[1140px] '>
      <p>
        <span className='font-bold'>Risk warning:</span> Derivative products are leveraged products and can result
        in losses. Please ensure you fully understand the risks and take care
        to manage your exposure and seek independent advice if necessary. It’s
        important for you to consider relevant legal documents before you decide
        whether or not to trade any of our products. All our trading accounts
        have negative balance protection.
      </p>

      <p>
        Egmarkets is the trading name of Eagle GM Limited, a company
        registered in Nigeria with RC 1322866 at address Floor 4, The Bureau, 28
        Raymond Njoku Street, S.W. Ikoyi, Lagos.
      </p>

      <p>
        Egmarkets (UK) Limited is registered in the United Kingdom,
        34 South Molton Street, Mayfair, W1K 4RG. Company Number: 12155018.
      </p>

      <p>
        EGM Analytics Limited is registered in the United Kingdom, 20-22 Wenlock
        Road, London N1 7GU. Company Number: 12901768.
      </p>
    </div>
  )
}

export default FooterCompanyInfo
