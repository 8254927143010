import React from "react"
import ctl from "@netlify/classnames-template-literals"

function RightArrowIcon({ arrowLight, arrowBlue, arrowGreen }) {
  const rightArrowStyle = ctl(`inline-block ml-2`)

  let stroke = '#000'

  if(arrowBlue){
    stroke = '#044dcc'
  }

  if(arrowLight){
    stroke = '#ffffff'
  }

  if(arrowGreen){
    stroke = '#40bb78'
  }

  return (
    <svg className={rightArrowStyle} xmlns="http://www.w3.org/2000/svg" width="15.598" height="15.598" viewBox="0 0 15.598 15.598">
      <defs>
        <style>
          {
            `
              .right-arrow{stroke-width:.7px;fill-rule:evenodd}
            `
          }
        </style>
      </defs>
      <g id="arrow-right-circle" transform="translate(.35 .35)">
        <path fill={stroke} stroke={stroke} id="Path_9370" data-name="Path 9370" className='right-arrow'
              d="M7.449 13.967A6.518 6.518 0 1 0 .931 7.449a6.518 6.518 0 0 0 6.518 6.518zm0 .931A7.449 7.449 0 1 0 0 7.449 7.449 7.449 0 0 0 7.449 14.9z" />
        <path fill={stroke} stroke={stroke} id="Path_9371" data-name="Path 9371" className='right-arrow'
              d="M17.009 16.505a.466.466 0 0 1 0-.659l2.465-2.463-2.465-2.464a.466.466 0 0 1 .659-.659l2.793 2.793a.466.466 0 0 1 0 .659l-2.793 2.793a.466.466 0 0 1-.659 0z"
              transform="translate(-9.89 -5.934)" />
        <path fill={stroke} stroke={stroke} id="Path_9372" data-name="Path 9372" className='right-arrow'
              d="M10.125 17.341a.466.466 0 0 1 .466-.466h4.656a.466.466 0 0 1 0 .931h-4.656a.466.466 0 0 1-.466-.465z"
              transform="translate(-5.935 -9.892)" />
      </g>
    </svg>
  )
}

export default RightArrowIcon
