import React, { useContext } from "react"
import NotificationCategory from "../category"
import { NOTIFICATION_CATEGORIES } from "../constants"
import useOpenCategories from "../hooks/useOpenCategories"
import { NotificationsContext } from "../../../../context/notifications"

const Categories = () => {
  const { currentlyOpenCategory, onToggleCategory } = useOpenCategories()
  const { notifications } = useContext(NotificationsContext)

  return (
    <>
      <NotificationCategory
        title="FINANCIAL MARKET NEWS"
        count={notifications.count.financialMarkets}
        items={notifications.financialMarkets}
        type={NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS}
        onToggle={onToggleCategory(NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS)}
        isOpen={currentlyOpenCategory === NOTIFICATION_CATEGORIES.FINANCIAL_MARKETS}
      />
      <NotificationCategory
        title="LIVE WEBINAR EVENTS"
        count={notifications.count.webinars}
        items={notifications.webinars}
        type={NOTIFICATION_CATEGORIES.WEBINARS}
        onToggle={onToggleCategory(NOTIFICATION_CATEGORIES.WEBINARS)}
        isOpen={currentlyOpenCategory === NOTIFICATION_CATEGORIES.WEBINARS}
      />
      <NotificationCategory
        title="ECONOMIC CALENDAR EVENTS"
        count={notifications.count.economicCalendar}
        items={notifications.economicCalendar}
        hideBorder
        type={NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR}
        onToggle={onToggleCategory(NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR)}
        isOpen={currentlyOpenCategory === NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR}
      />
    </>
  )
}

export default Categories