import React from "react"
import ctl from "@netlify/classnames-template-literals"
import { Link } from "gatsby"
import dropdownIcon from "icons/dropdown.svg"

const badgeStyling = ctl(`
    py-0.5 px-[7px] bg-button-blue w-[fit-content] text-white font-bold
    flex items-center
`)

const SubmenuItems = ({ data, parentSlug }) => {
  const submenuItemsWrapperStyle = ctl(
    `filter drop-shadow-menu-options opacity-0  py-2 border-[1.5px] border-black top-[60px] rounded absolute bg-white left-0 invisible
     group-hover:visible group-hover:opacity-100 group-hover:top-[40px] transition-all duration-300
     pointer-events-none group-hover:pointer-events-auto
     `
  )

  return (
    <ol className={submenuItemsWrapperStyle}>
      {data.map((item, index) => {
        const subMenuItemName = item.name
        const subMenuItemUrl =
          parentSlug === "" ? `/${item.slug}` : `/${parentSlug}/${item.slug}`

        const submenuItemStyle = ctl(
          `
          font-normal block 
          ${!item.comingSoon && 'min-w-[110px] 1.5xl:min-w-[130px]'}
           w-max relative py-2
           pl-3 1.5xl:pl-5 pr-4`
        )

        let link;

        if(item.noLink){
          link = <span> {subMenuItemName} </span>
        }else{
          if(item.hasOwnProperty("isExternal")){
            link = (
              <a href={item.slug} target="_blank" rel="noreferrer">
                {subMenuItemName}
              </a>
            )
          }else{
            link = <Link to={subMenuItemUrl}>{subMenuItemName}</Link>
          }
        }

        return (
          <li className={submenuItemStyle} key={index}>
            {
              item.comingSoon && (
                <span className={`${badgeStyling} h-[14px] leading-[10px] text-[9px] rounded-[7px]`}>Coming soon</span>
              )
            }
            {link}
          </li>
        )
      })}
    </ol>
  )
}

const MenuItems = ({ data }) => {
  const menuItemStyle = ctl(
    `inline-block  pb-2 pt-3 font-semibold group relative cursor-pointer flex whitespace-pre`
  )

  const dropdownIconStyle = ctl(`ml-2`)

  return data.map((item, index) => {
    const menuItem = item
    const menuItemName = menuItem.name

    const hasSubmenu = menuItem.submenu

    return (
      <li className={menuItemStyle} key={index}>
        {
          item.isNew && (
            <span className={`${badgeStyling} absolute h-[15px] text-[10px] leading-[12px] rounded-[8px] top-[-2px] right-0`}>New!</span>
          )
        }
        {item.hasOwnProperty("isExternal") ? (
          <a href={menuItem.slug} target="_blank" rel="noreferrer">
            {menuItemName}
          </a>
        ) : (
          <>
            {menuItemName}
            <img src={dropdownIcon} alt="" className={dropdownIconStyle} />
          </>
        )}

        {hasSubmenu && (
          <SubmenuItems data={menuItem.submenu} parentSlug={menuItem.slug} />
        )}
      </li>
    )
  })
}

export default MenuItems
