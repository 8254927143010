import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"
import RightArrowIcon from "../../../assets/icons/right-arrow"

const Button = ({
  type,
  label,
  url,
  cta,
  isExternal,
  inGroup,
  hasArrow,
  arrowLight,
  hasMargin = true,
  regularFontWeight = false,
  isButton,
  onClick,
  arrowBlue,
  ...props
}) => {
  const buttonStyle = ctl(`
  border-2 
  border-button-blue 
  rounded 
  leading-none
  ${regularFontWeight ? 'font-normal' : 'font-semibold'}
  inline-block
  ${hasMargin && 'mt-3'}
  text-xs
  py-3
  text-center

  ${cta && 'px-8 text-sm'}
  ${inGroup ? 'px-0 w-full !mt-0 md:w-[46%]' : 'md:px-8 px-7'}
  
  ${
    type === "primary" &&
    `bg-button-blue
  text-white 
  `
  }
  ${
    type === "secondary" &&
    `
    bg-white
  text-button-blue 
  `
  }
  ${
    type === "tertiary" &&
    ` border-none
  bg-transparent
  text-button-blue 
  !px-0
  `
  }

 
  
  ${props.className}
  `)

  const buttonContent = (
    <>
      {label}
      {hasArrow && <RightArrowIcon arrowLight={arrowLight} arrowBlue={arrowBlue || ["tertiary", "secondary"].includes(type)}/>}
    </>
  )

  if(isButton){
    return (
      <button onClick={onClick} className={buttonStyle}>
        {buttonContent}
      </button>
    )
  }else if(isExternal){
    return (
      <a href={url} className={buttonStyle} target="_blank" rel="noreferrer">
        {buttonContent}
      </a>
    )
  }

  return (
    <Link to={`${url}${(!isExternal && url !== '#') && '/'}`} className={buttonStyle}>
      {label}

      {hasArrow && <RightArrowIcon arrowLight={arrowLight} arrowBlue={arrowBlue || ["tertiary", "secondary"].includes(type)}/>}
    </Link>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]).isRequired,
  url: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  cta: PropTypes.bool,
}

Button.defaultProps = {
  type: "primary",
  url: "/",
  isExternal: false,
  cta: false,
}

export default Button
