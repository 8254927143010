import React from "react"
import ctl from "@netlify/classnames-template-literals"
import FooterCompanyInfo from "./company-info"
import FooterLinks from "./links"
import SocialMediaIcons from "../../utils/social-icons"
import NavigationGrid from "../../utils/navigation-grid"
import netShape from "../../../assets/images/layout/footer/net_shape.svg"
import netShapeM from "../../../assets/images/layout/footer/net_shape-m.svg"

const MainFooter = ({className}) => {
  const footerStyle = ctl(`
  bg-footer-texture
  text-white
  md-max:pt-[50px]
  py-24
  main-footer
  mt-[100px]
  md:mt-[200px]
  relative
  overflow-hidden
  ${className}
 
  `)

  const footerSection = ctl(`
  flex
  justify-between
  mb-12
  md:flex-row
  flex-col
  md-max:px-8
  `)

  const netImage = ctl(`
    absolute
    md:top-[-100px]
    top-0
    md:w-[1620px]
    md:max-w-none
    md:left-[-20px]
    md-max:hidden
  `)

  return (
    <footer className={footerStyle}>
      <img src={netShape} alt="" className={netImage} />
      <img src={netShapeM} alt="" className='absolute top-0 left-0 md:hidden' />
      <div className='mx-auto px-6 max-w-screen-lg 1.5xl:max-w-screen-xl z-20 relative 1.5xl:pl-[30px] 1.5xl:pr-0' >
        <section className={footerSection}>
          <FooterLinks />
          <NavigationGrid />
        </section>

        <SocialMediaIcons isFooter isMobile />
        <FooterCompanyInfo />
      </div>
    </footer>
  )
}

export default MainFooter
