import React, { useRef } from "react"
import Categories from "./categories"

const MobileNotifications = ({ show, onHide }) => {
  const wrapperRef = useRef();

  const handleClickWrapper = ({target}) => {
    if(target === wrapperRef.current){
      onHide()
    }
  }

  return (
    <div
      className={`fixed w-full top-[5rem] left-0 bg-black bg-opacity-60 lg:hidden z-[10] px-[18px] flex justify-center pt-[30px] ${!show && 'pointer-events-none opacity-0'}`}
      style={{height: 'calc(100% - 5rem)'}}
      ref={wrapperRef}
      onClick={handleClickWrapper}
    >
      <div
        className="shadow-notification border-[0.5px] border-black rounded-[21px] overflow-hidden w-full relative h-[fit-content] max-w-[400px] mx-auto"
      >
        <Categories/>
      </div>
    </div>
  )
}

export default MobileNotifications