import React from "react"
import menuItemsData from "data/menu.json"
import MenuItems from "./items"
import ctl from "@netlify/classnames-template-literals"

const MainMenu = () => {
  const menuItemsListStyle = ctl(`flex justify-between`)
  const navStyle = ctl(`w-7/12 lg:block hidden`)
  return (
    <nav className={navStyle}>
      <ol className={menuItemsListStyle}>
        <MenuItems data={menuItemsData} />
      </ol>
    </nav>
  )
}

MainMenu.propTypes = {}

export default MainMenu
