import React from "react"
import { Link } from "gatsby"
import logo from "./egmarkets.svg"
import logoLight from "./egmarkets-light.svg"
import ctl from "@netlify/classnames-template-literals"

const EgMarketsLogo = ({ isLight, className }) => {
  const logoStyle = ctl(`md:w-[180px] w-[139px] lg:pb-[12px] xs-max:w-[100px]`)
  return (
    <Link to="/" className={className}>
      <img
        src={isLight ? logoLight : logo}
        alt="Egmarkets"
        className={logoStyle}
      />
    </Link>
  )
}

export default EgMarketsLogo
