import React from "react"
import PropTypes from "prop-types"
import ctl from "@netlify/classnames-template-literals"

const Container = ({size, children, style, disableXLPadding = false, ...props}) => {
  //This boolean indicates whether a particular size variant already has padding stylings attached to it and so therefore we should not override it
  const sizeHasNoPadding = ["default", "slide"].includes(size);
  const containerStyle = ctl(`
  mx-auto
  ${sizeHasNoPadding && "md:px-12"} 
  ${(!sizeHasNoPadding || disableXLPadding) ? '' : 'xl:px-1'}
  px-6
  ${size === "default" && "max-w-screen-lg"}
  ${size === "large" && "max-w-screen-xl md:px-[70px]"}
  ${size === "slide" && "max-w-[1150px]"}
  ${size === "header" && "max-w-[1368px] md:px-[60px]"}
  ${size === "medium" && "max-w-screen-lg md:px-10 1.5xl:px-1"}
  ${size === "small" && "max-w-screen-md md:px-0"}

  ${props.className}
  `)
    return <section style={style} className={containerStyle}>{children}</section>
}

Container.propTypes = {
    children: PropTypes.node.isRequired,
}

Container.defaultProps = {
    size: "default",
}

export default Container
