import { useContext, useState } from "react"
import { NOTIFICATION_CATEGORIES } from "../constants"
import { NotificationsContext } from "../../../../context/notifications"

const useOpenCategories = () => {
  const [currentlyOpenCategory, setCurrentlyOpenCategory] = useState(NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR)
  const { onOpenCategory } = useContext(NotificationsContext);

  const onToggleCategory = (category) => () => {
    if (currentlyOpenCategory === category) {
      setCurrentlyOpenCategory(null)
    } else {
      onOpenCategory(category)
      setCurrentlyOpenCategory(category)
    }
  }

  return { onToggleCategory, currentlyOpenCategory }
}

export default useOpenCategories