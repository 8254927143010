import React from "react"
import menu from "src/data/menu.json"
import { Link } from "gatsby"
import ctl from "@netlify/classnames-template-literals"

const SubMenuLinks = ({ submenu, parentSlug, mobileNav }) => {
  const listItemStyle = ctl(`text-sm`)

  const renderItem = ({ item, subMenuItemName, subMenuItemUrl }) => {
    if(item.noLink){
      return (
        <span>
          {subMenuItemName}
        </span>
      )
    }


    if(item.isExternal){
      return (
        <a href={item.slug} target="_blank" rel="noreferrer">
          {subMenuItemName}
        </a>
      )
    }

    return <Link to={subMenuItemUrl}>{subMenuItemName}</Link>
  }
  return (
    <ol className={`grid ${mobileNav ? 'gap-y-[30px]' : 'gap-y-4'}`}>
      {submenu.map((item, index) => {
        const subMenuItemName = item.name
        const subMenuItemUrl =
          parentSlug === "" ? `/${item.slug}` : `/${parentSlug}/${item.slug}`
        return (
          <li className={`${listItemStyle} ${item.footerTopMargin && 'md:mt-[14px]'}`} key={index}>
            {
              item.comingSoon && (
                <span className={`py-0.5 px-[7px] leading-none text-[10px] w-[fit-content] rounded-[7px] ${mobileNav ? 'text-white bg-button-blue' : 'text-button-blue bg-white'} font-bold block`}>
                  Coming soon
                </span>
              )
            }
            {renderItem({item, subMenuItemName, subMenuItemUrl})}
          </li>
        )
      })}
    </ol>
  )
}

const navigationMenuHeading = ctl(`font-extrabold mb-4 text-sm`)

const NavigationGrid = () => {
  let mobileMenu = menu.slice(0, 4);
  mobileMenu.push(menu[4])
  mobileMenu.push(menu[5])

  const footerNavigation = ctl(
    `grid md:flex grid-cols-2 gap-x-[60px] gap-y-8 pt-4 md:mt-0 mt-8`
  )

  return (
    <div className={footerNavigation}>
      {mobileMenu.map((item, index) => {
        const heading = item.name

        if (!item.isExternal) {
          const submenu = item.submenu

          return (
            <div key={index}>
              <h4 className={navigationMenuHeading}>{heading}</h4>

              {
                submenu && (
                  <SubMenuLinks submenu={submenu} parentSlug={item.slug} />
                )
              }
            </div>
          )
        } else {
          return (
            <a target='_blank'  rel="noreferrer"  className={navigationMenuHeading} href={item.slug}>
              {item.name}
            </a>
          )
        }
      })}
    </div>
  )
}

export const NavBarGrid = () => {
  const renderItem = (item) => {
    const heading = item.name

    const submenu = item.submenu

    return (
      <div>
        <h4 className='font-extrabold mb-[30px] text-sm'>{heading}</h4>

        <SubMenuLinks mobileNav submenu={submenu} parentSlug={item.slug} />
      </div>
    )
  }

  return (
    <div className='pt-4 mt-8 mb-auto flex justify-between'>
      <section className='h-[fit-content] grid gap-y-[50px]'>
        {renderItem(menu[0])}
        {renderItem(menu[2])}
        <a href='#' className='text-sm text-white font-extrabold' target="_blank" rel="noreferrer">
          Indexa
        </a>
      </section>
      <section className='h-[fit-content] grid gap-y-[50px]'>
        {renderItem(menu[1])}
        {renderItem(menu[3])}
        {renderItem(menu[5])}
      </section>
    </div>
  )
}

export default NavigationGrid
