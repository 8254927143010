import React from "react"

const CountBadge = ({ className, children }) => {
  return (
    <span className={`w-[18px] h-[18px] text-xs font-bold text-white flex items-center justify-center rounded-[50%] bg-mango inline-block ${className}`}>
      {children}
    </span>
  )
}

export default CountBadge