import React from "react"
import format from "date-fns/format"

const WebinarsNotification = ({ title, date, link }) => {
  return (
    <div className='flex flex-col bg-white items-start pl-[25px] pr-[30px] py-4 border-b-black border-b-[0.5px]'>
      <h4 className='m-0 text-xs font-semibold text-black leading-[1.7] mb-[10px]'>
        {title}
      </h4>
      <footer className='flex items-center justify-between w-full'>
        <span className='text-[10px] text-black'>
          {format(new Date(date), 'eeee, do MMMM. K:mmaaa')}
        </span>
        <a href={link} rel='noreferrer' className='text-[10px] font-medium text-deep-blue'>
          Register
        </a>
      </footer>
    </div>
  )
}

export default WebinarsNotification