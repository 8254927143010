import React, { useContext, useState } from "react"
import ctl from "@netlify/classnames-template-literals"
import Container from "../../utils/container"
import SocialMediaIcons from "../../utils/social-icons"
import { NavBarGrid } from "../../utils/navigation-grid"
import "./mobile.css"
import notificationIcon from "icons/notification-desktop.svg"
import { NotificationsContext } from "../../../context/notifications"

const MobileMenu = ({ onToggleNotifications }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { notifications } = useContext(NotificationsContext)

  const toggleMenu = () => {
    setMenuIsOpen(menuIsOpen => !menuIsOpen)
  }

  const mobileMenuWrapper = ctl(`lg:hidden flex items-center `)
  const mobileNavStyle = ctl(
    `bg-navy w-full h-screen absolute 
    md:top-[92px] top-[66px] left-0 text-white md:py-12 py-2 
    ${menuIsOpen ? "visible" : "invisible"}
    `
  )

  const mobileMenuButtonStyle = ctl(
    `w-[30px] h-4 flex flex-col  justify-between bg-white`
  )
  const mobileMenuSpanStyle = ctl(
    `w-full bg-brownish-grey h-[2px] block rounded 
    origin-center transform transition-transform duration-300 ${
      menuIsOpen &&
      ` translate-x-[100px] even:w-[0px] first:rotate-45 
      first:translate-y-[8px] first:translate-x-0 last:-rotate-45 last:translate-y-[-6px] last:translate-x-0`
    } `
  )

  const socialIconsSectionStyle = ctl(`md:mt-12 mt-8`)
  return (
    <div className={mobileMenuWrapper}>
      <button
        className="relative mr-5"
        onClick={onToggleNotifications}
      >
        <span className={`rounded-[50%] bg-mango inline-block absolute left-[-2px] w-2 h-2 ${!notifications.totalCount && 'hidden'}`}/>
        <img src={notificationIcon} className='w-[14px]' alt="Show notifications" />
      </button>
      <a
        className='text-base text-black font-semibold mr-[20px]'
        href="https://clients.eagleglobalmarkets.com/#/auth/login"
        target='_blank'
      >
        Log in
      </a>
      <button className={mobileMenuButtonStyle} onClick={toggleMenu}>
        <span className={mobileMenuSpanStyle}></span>
        <span className={mobileMenuSpanStyle}></span>
        <span className={mobileMenuSpanStyle}></span>
      </button>
      {
        menuIsOpen && (
          <nav className={mobileNavStyle}>
            <Container className='md-max:px-10 flex flex-col mobile-container pb-[60px]'>
              <NavBarGrid />
              <section className={socialIconsSectionStyle}>
                <SocialMediaIcons />
                <SocialMediaIcons isMobile />
              </section>
            </Container>
          </nav>
        )
      }
    </div>
  )
}

MobileMenu.propTypes = {}

export default MobileMenu
