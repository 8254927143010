import React from "react"
import formatDistanceToNow from "date-fns/formatDistanceToNow"

const EconomicCalendar = ({ item, hideBorder }) => {
  return (
    <div
      className={`flex bg-white items-start pl-[25px] pr-[30px] py-4 ${!hideBorder && "border-b-black border-b-[0.5px]"}`}
    >
      <span className="inline-block text-xs text-black mr-[11px] w-[85px]">
        {formatDistanceToNow(new Date(item.date))} ago
      </span>
      <span className="w-[17px] h-[17px] rounded-[50%] bg-black mr-[5px]" />
      <span className="w-[17px] h-[17px] rounded-[50%] bg-black mr-[14px]" />
      <div>
        <p className="text-xs text-black mb-[5px] mt-0">
          {item.title}
        </p>
        <p className="mb-0 text-xs text-black space-x-4 mt-0">
          <span>A: {item.a}%</span>
          <span>F: {item.f1}%</span>
          <span>F: {item.f2}%</span>
        </p>
      </div>
    </div>
  )
}

export default EconomicCalendar