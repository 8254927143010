import React from "react"
import ctl from "@netlify/classnames-template-literals"
import twitterIcon from "icons/social/twitter.svg"
import facebookIcon from "icons/social/facebook.svg"
import instagramIcon from "icons/social/instagram.svg"
import youtubeIcon from "icons/social/youtube.svg"
import linkedinIcon from "icons/social/linkedin.svg"

const SocialIcon = ({ icon, url }) => {
  const socialIconStyle = ctl(`inline-block mr-4`)
  const socialIconImageStyle = ctl(`w-7 h-7`)

  return (
    <a href={url} target="_blank" rel="noreferrer" className={socialIconStyle}>
      <img src={icon} className={socialIconImageStyle} alt="" />
    </a>
  )
}

const SocialMediaIcons = ({ isMobile, isFooter = false }) => {
  let footerIconsStyle = 'md:block hidden'

  if(isMobile){
    if(isFooter){
      footerIconsStyle = 'md:hidden relative flex justify-center'
    }else{
      footerIconsStyle = 'md:hidden block'
    }
  }

  return (
    <section className={footerIconsStyle}>
      <SocialIcon icon={twitterIcon} url="https://twitter.com/egmarkets" />
      <SocialIcon
        icon={facebookIcon}
        url="https://web.facebook.com/eagleglobalmarkets/?_rdc=1&_rdr"
      />
      <SocialIcon
        icon={instagramIcon}
        url="https://www.instagram.com/egmarkets/"
      />
      <SocialIcon
        icon={youtubeIcon}
        url="https://www.youtube.com/channel/UCygWKOVLhXCZFg8KcLtw7kg"
      />
      <SocialIcon
        icon={linkedinIcon}
        url="https://www.linkedin.com/company/eagle-global-markets-egm-/"
      />
    </section>
  )
}

export default SocialMediaIcons
