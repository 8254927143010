/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Seo from "./seo"
import MainFooter from "./footer"
import Fade from "react-reveal/Fade"
import MainHeader from "./header"
import Notifications from "./notifications"
import MobileNotifications from "./notifications/mobile-notifications"
import useShowNotifications from "./notifications/hooks/useShowNotifications"

const Layout = ({ meta, children, title, description, ignoreSiteName = false, footerClassName, disableOverflow = false }) => {
  const { showNotifications, setShowNotifications, handleToggleNotifications } = useShowNotifications()

  return (
    <>
      <Seo description={description} meta={meta} title={title} ignoreSiteName={ignoreSiteName} />
      <MainHeader showNotifications={showNotifications} onToggleNotifications={handleToggleNotifications}/>
      <MobileNotifications show={showNotifications} onHide={() => setShowNotifications(false)}/>
      <Notifications/>
      <main className="relative">
        <Fade bottom distance="100px">
          <div className={`${!disableOverflow && "overflow-hidden"}`}>{children}</div>
        </Fade>
      </main>
      <MainFooter className={footerClassName}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Layout
