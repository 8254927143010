import { useCallback, useEffect } from "react"

const useCallOnClickOutside = ({ itemRef, action }) => {
  const onWindowClick = useCallback(
    (event) => {
      if (!itemRef.current) return;

      const item = itemRef.current;
      if (!item.contains(event.target)) {
        action?.();
      }
    },
    [itemRef, action]
  );

  useEffect(() => {
    if (document) {
      document.addEventListener('click', onWindowClick, {
        capture: true,
      });
    }

    return () =>
      document.removeEventListener('click', onWindowClick, {
        capture: true,
      });
  }, [onWindowClick]);
};

export default useCallOnClickOutside;
