import { useContext, useState } from "react"
import { NOTIFICATION_CATEGORIES } from "../constants"
import { NotificationsContext } from "../../../../context/notifications"

const useShowNotifications = () => {
  const { onOpenCategory } = useContext(NotificationsContext)

  const [showNotifications, setShowNotifications] = useState(false)
  //Economic calendar is open by default so we have to call on open category for it on the first click of the bell icon
  const [hasSetEconomicCalendar, setHasSetEconomicCalendar] = useState(false);

  const handleToggleNotifications = () => {
    setShowNotifications(prevState => {
      if(!hasSetEconomicCalendar){
        onOpenCategory(NOTIFICATION_CATEGORIES.ECONOMIC_CALENDAR)
        setHasSetEconomicCalendar(true)
      }

      return !prevState
    })
  }

  return { showNotifications, setShowNotifications, handleToggleNotifications }
}

export default useShowNotifications