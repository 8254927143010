import React, { useContext, useRef } from "react"
import notificationIcon from "icons/notification-desktop.svg"
import { AnimatePresence, motion } from "framer-motion"
import CountBadge from "./count-badge"
import { NotificationsContext } from "../../../context/notifications"
import Categories from "./categories"
import useShowNotifications from "./hooks/useShowNotifications"
import useOnClickOutside from "../../../hooks/useOnClickOutside"

const animationVariants = {
  hide: {
    opacity: 0,
    x: 15
  },
  show: {
    x: 0,
    opacity: 1
  }
}

const Notifications = () => {
  const { notifications } = useContext(NotificationsContext)

  const { showNotifications, handleToggleNotifications, setShowNotifications } = useShowNotifications()

  const buttonRef = useRef();

  useOnClickOutside({
    itemRef: buttonRef,
    action: () => setShowNotifications(false)
  })

  return (
    <div ref={buttonRef} className="lg-max:hidden fixed bottom-[40px] right-[60px] z-[10] w-[333px]">
      <AnimatePresence>
        {
          showNotifications && (
            <motion.div
              className="absolute bottom-[70px] left-[-47px] shadow-notification border-[0.5px] border-black rounded-[21px] overflow-hidden w-full"
              initial="hide"
              animate="show"
              exit="hide"
              variants={animationVariants}
              transition={{duration: 0.15}}
            >
              <Categories/>
            </motion.div>
          )
        }
      </AnimatePresence>
      <button
        className="w-[72px] h-[72px] border border-brownish-grey rounded-[50%] ml-auto shadow-notification flex items-center justify-center z-[12] relative border-brownish-grey bg-white"
        onClick={handleToggleNotifications}
      >
        <CountBadge className={`absolute left-[13px] top-[15px] ${!notifications.totalCount && 'hidden'}`}>
          {notifications.totalCount}
        </CountBadge>
        <img src={notificationIcon} className='w-8' alt="Show notifications" />
      </button>
    </div>
  )
}

export default Notifications