import React from "react"
import EgMarketsLogo from "assets/logo/EgMarketsLogo"
import Container from "../../utils/container"
import ctl from "@netlify/classnames-template-literals"
import Button from "../../utils/button"
import MainMenu from "../menu"
import MobileMenu from "../menu/mobile"

const MainHeader = ({ onToggleNotifications }) => {
  // shadow-md
  const headerStyle = ctl(`
  shadow-header
  md:py-6
  py-4
  bg-white
  sticky
  top-0
  z-[100]
  md-max:flex md-max:items-center md-max:h-20
  `)

  const headerSectionStyle = ctl(`
    flex
    items-center
    justify-between
  `)

  const loginButtonStyle = ctl(`lg:block hidden mt-0 ml-20 relative transform translate-y-0.5`)
  return (
    <header className={headerStyle}>
      <Container size="header" className='w-full'>
        <section className={headerSectionStyle}>
          <EgMarketsLogo />
          <MainMenu />
          <Button
            label="Login"
            url="https://clients.eagleglobalmarkets.com/#/auth/login"
            isExternal
            cta
            className={loginButtonStyle}
            hasMargin={false}
          />
          <MobileMenu onToggleNotifications={onToggleNotifications}/>
        </section>
      </Container>
    </header>
  )
}

export default MainHeader
